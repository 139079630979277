import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { HEADER_HEIGHT } from '@/components/Header/Header.constants';
import { CustomLink } from '@/components/CustomLink/CustomLink';

const WebViewHeaderBox = css`
  position: fixed;
  width: 100%;
  z-index: 10050;
  top: 0;
`;

export const HeaderBox = styled.header<{
  $hasDivider?: boolean;
  $hasCustomHeader?: boolean;
  $hasLeadingAction?: boolean;
  $hasWhiteBg?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ $hasCustomHeader, $hasLeadingAction }) =>
    $hasLeadingAction
      ? $hasCustomHeader
        ? 'auto 1fr'
        : 'auto 1fr auto'
      : '1fr'};
  justify-items: center;
  align-items: center;
  padding: 0 15px;
  height: ${HEADER_HEIGHT}px;
  background-color: ${({ theme, $hasWhiteBg }) =>
    $hasWhiteBg
      ? theme.palette.primary.background
      : theme.palette.secondary.background};

  ${({ $hasDivider }) =>
    $hasDivider &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
    `}

  ${({ $hasCustomHeader }) =>
    $hasCustomHeader &&
    css`
      gap: 15px;
      justify-items: start;
    `}

  ${({ theme }) => theme.isWebView && WebViewHeaderBox}
`;

export const HeaderTitle = styled(Typography.Header)`
  transform: translateX(-10px);
  @media (min-width: 375px) {
    transform: translateX(0);
  }
`;

export const NewAdLink = styled(CustomLink)`
  justify-self: end;
`;
