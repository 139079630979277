import React from 'react';
import * as S from './SkeletonLoader.styles';
import { SkeletonLoaderProps } from '@/components/SkeletonLoader/SkeletonLoader.types';

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  borderRadius = 4,
  width = '100%',
  height = '15px',
  ...props
}) => {
  return (
    <S.Box
      {...props}
      $borderRadius={borderRadius}
      $width={width}
      $height={height}
      data-testid="skeleton-loader"
      suppressHydrationWarning
    />
  );
};
