import { create } from 'zustand';

interface AndroidBannersStore {
  isAndroidBannerVisible: boolean;
  setIsAndroidBannerVisible: (value: boolean) => void;
}

export const useAndroidBannersStore = create<AndroidBannersStore>(set => ({
  isAndroidBannerVisible: false,
  setIsAndroidBannerVisible: value => set({ isAndroidBannerVisible: value }),
}));
