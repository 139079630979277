import React from 'react';

export type Props = {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  isInteractionHappened: boolean;
  setIsInteractionHappened: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToInput: () => void;
  offsetTop: number;
};

export enum KeywordsSource {
  Suggestion = 'search_suggestion',
  Typewritten = 'typewritten',
}
