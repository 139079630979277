import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './FiltersDrawer.styles';
import { Drawer } from '@/components/Drawer/Drawer';
import { DrawerItems } from '@/components/Drawer/Drawer.styles';
import { Select } from '@/components/UI/Select/Select';
import { Button } from '@/components/UI/Button/Button';
import { Input } from '@/components/UI/Input/Input';
import { CitiesDrawer } from '@/components/CitiesDrawer/CitiesDrawer';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';
import { SortKeys } from '@/components/FiltersDrawer/FiltersDrawer.types';
import { sortItems } from '@/components/FiltersDrawer/FiltersDrawer.constants';

interface CatalogueDrawerProps {
  isOpen: boolean;
  close: () => void;
}

export const FiltersDrawer: React.FC<CatalogueDrawerProps> = ({
  isOpen,
  close,
}) => {
  useBodyOverflow(isOpen);

  const { t } = useTranslation();
  const [prices, setPrices] = useState({ from: '', to: '' });
  const [isCitiesOpen, setIsCitiesOpen] = useState(false);
  const [sort, setSort] = useState<SortKeys>('by_date');
  const [city, setCity] = useState('');

  function toggleCitiesSubDrawer() {
    setIsCitiesOpen(prev => !prev);
  }

  function applyFilters() {
    console.table({ prices, city, sort });
    close();
  }

  function changePrice(e: string, fieldName: keyof typeof prices) {
    setPrices({ ...prices, [fieldName]: e });
  }

  function renderSortItem(key: SortKeys) {
    return (
      <S.FiltersDrawerSortItem
        key={key}
        data-testid="sort-item"
        $isActive={sort === key}
        onClick={() => setSort(key)}
      >
        {t(`common.${key}`)}
      </S.FiltersDrawerSortItem>
    );
  }

  return (
    <Drawer
      isOpen={isOpen}
      close={close}
      title={t('common.filters')}
      isInPortal
    >
      <DrawerItems>
        <S.FiltersDrawerTitle $variant="3">
          {t('common.price_azn')}
        </S.FiltersDrawerTitle>
        <S.FiltersDrawerPrices>
          <Input
            value={prices.from}
            onChange={value => changePrice(value as string, 'from')}
            clear={() => setPrices({ ...prices, from: '' })}
            type="text"
            placeholder={t('common.from')}
            isNumberWithSpaces
          />
          <Input
            value={prices.to}
            onChange={value => changePrice(value as string, 'to')}
            clear={() => setPrices({ ...prices, to: '' })}
            type="text"
            placeholder={t('common.to')}
            isNumberWithSpaces
          />
        </S.FiltersDrawerPrices>

        <Select
          label={t('common.city')}
          toggle={toggleCitiesSubDrawer}
          value={city}
          onClear={() => setCity('')}
        >
          <CitiesDrawer
            isOpen={isCitiesOpen}
            close={toggleCitiesSubDrawer}
            selected={city}
            onSelect={setCity}
          />
        </Select>
      </DrawerItems>

      <DrawerItems $isLast>
        <S.FiltersDrawerTitle $variant="3">
          {t('common.sorting')}
        </S.FiltersDrawerTitle>
        <S.FiltersDrawerSortItems>
          {sortItems.map(key => renderSortItem(key))}
        </S.FiltersDrawerSortItems>
      </DrawerItems>

      <S.FiltersDrawerCTA>
        <Button appearance="primary" isFullWidth onClick={applyFilters}>
          {t('common.show')}
        </Button>
      </S.FiltersDrawerCTA>
    </Drawer>
  );
};
