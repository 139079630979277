import React from 'react';
import * as S from './Spinner.styles';
import { SpinnerProps } from '@/components/Spinner/Spinner.types';

export const Spinner: React.FC<SpinnerProps> = ({ size = 's' }) => {
  return (
    <S.SpinnerBox $size={size}>
      {Array.from({ length: 8 }, (_, i) => i + 1).map(i => (
        <S.SpinnerItem key={i} $size={size} />
      ))}
    </S.SpinnerBox>
  );
};
