import React, { PropsWithChildren } from 'react';
import * as S from './Badge.styles';
import { BadgeProps } from '@/components/UI/Badge/Badge.types';
import { sliceValue } from '@/components/UI/Badge/Badge.helpers';

export const Badge: React.FC<PropsWithChildren<BadgeProps>> = ({
  children,
  value,
  appearance = 'main',
}) => {
  if (!value) {
    return children;
  }

  return (
    <S.Box>
      <S.Value
        $variant="3"
        as="span"
        $appearance={appearance}
        data-testid="BadgeValue"
      >
        {sliceValue(value)}
      </S.Value>

      {children}
    </S.Box>
  );
};
