import React, { PropsWithChildren } from 'react';
import Link from 'next/link';
import * as S from './Chip.styles';
import { ChipProps } from '@/components/UI/Chip/Chip.types';

export const Chip: React.FC<PropsWithChildren<ChipProps>> = ({
  children,
  appearance = 'default',
  size = 'l',
  isRounded,
  href,
  ...props
}) => {
  return (
    <S.ChipBox
      {...props}
      {...(!!href && { as: Link, href })}
      $appearance={appearance}
      $size={size}
      $isRounded={isRounded}
    >
      {children}
    </S.ChipBox>
  );
};
