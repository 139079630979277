import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/UI/Button/Button';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { getReversedLangDefinition } from '@/utils/helpers';
import { useChangeLang } from '@/components/ChangeLang/useChangeLang';
import { TypographyProps } from '@/components/UI/Typography/Typography.types';

interface ChangeLangProps {
  isShort?: boolean;
  typography?: React.ElementType;
  typographyProps?: TypographyProps;
}

export const ChangeLang: React.FC<ChangeLangProps> = ({
  isShort,
  typographyProps = {},
  typography: TypographyComponent = Typography.Body,
}) => {
  const { i18n } = useTranslation();
  const changeLang = useChangeLang();

  return (
    <Button
      appearance="transparent"
      onClick={changeLang}
      hasPadding={false}
      isAutoWidth
      data-testid="change-lang"
    >
      <TypographyComponent {...typographyProps}>
        {isShort
          ? getReversedLangDefinition(i18n.language).short.toUpperCase()
          : getReversedLangDefinition(i18n.language).long}
      </TypographyComponent>
    </Button>
  );
};
