import React from 'react';
import * as S from './SubcategoriesScrollGrid.styles';
import { BaseCategory } from '@/api/types/categories.types';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';

interface SubcategoriesScrollGridProps {
  subcategories: BaseCategory[];
}

export const SubcategoriesScrollGrid: React.FC<
  SubcategoriesScrollGridProps
> = ({ subcategories }) => {
  const customPush = useCustomPush();

  return (
    <S.SubcategoriesScrollGridBox>
      {subcategories.map(subcategory => {
        return (
          <S.SubcategoriesScrollGridItem
            key={subcategory.id}
            {...(subcategory.path && {
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick: () => customPush(`/${subcategory.path}`),
            })}
          >
            {subcategory.name}
          </S.SubcategoriesScrollGridItem>
        );
      })}
    </S.SubcategoriesScrollGridBox>
  );
};
