import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { useGetUrlAndHashFromPath } from '@/hooks/useGetUrlAndHashFromPath';
import { ROUTES } from '@/constants/routes';
import { QUERY } from '@/constants/common';
import { Properties } from '@/api/types/searchSuggestions.types';
import { useSaveScrollPositionActions } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { KeywordsSource } from '@/components/SearchSuggestions/SearchSuggestions.types';

interface SearchParams {
  categoryPath?: string;
  properties?: Properties;
  keywordsSource: KeywordsSource;
}

export function useSearch() {
  const customPush = useCustomPush();
  const { url } = useGetUrlAndHashFromPath();
  const saveScrollPositionActions = useSaveScrollPositionActions();

  function search(query: string, params?: SearchParams) {
    saveScrollPositionActions();

    const queryParams = {
      [`${QUERY.q}[${QUERY.keywords}]`]: query,
      button: '',
      mobile_site: true,
      keywords_source: params?.keywordsSource ?? '',
    };

    if (params?.properties) {
      Object.entries(params.properties).forEach(([key, value]) => {
        queryParams[`p[${key}]`] = String(value);
      });
    }

    const getPathname = () => {
      if (params?.categoryPath) return `/${params?.categoryPath}`;

      return url.includes(ROUTES.ad) ? url : ROUTES.ad;
    };

    customPush({
      pathname: getPathname(),
      query: queryParams,
    });
  }

  return search;
}
