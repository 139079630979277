import styled, { css } from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { Button } from '@/components/UI/Button/Button';

export const BurgerNavigation = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  width: 80%;
  background-color: ${({ theme }) => theme.palette.primary.background};
  overflow-y: auto;
  transition: 0.3s ease-in-out;
  z-index: 10100;
  padding: 0 15px;
`;

export const BurgerNavClose = styled(Button)`
  margin-left: auto;
`;

export const BurgerNavItem = styled(Typography.Body)<{
  $isHead?: boolean;
  $isLast?: boolean;
  $isContactUsItem?: boolean;
  $leftIcon?: { name: string; width?: number; height?: number };
}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  gap: 15px;
  color: ${({ theme }) => theme.palette.primary.labels};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
  position: relative;

  ${({ $isHead }) =>
    $isHead &&
    css`
      justify-content: space-between;
      padding-right: 0;
      border-bottom: none;
      margin-bottom: 20px;
    `}

  ${({ $leftIcon }) =>
    !!$leftIcon &&
    css`
      position: relative;

      &::before {
        content: '';
        background-image: url('/images/${$leftIcon.name}');
        width: ${() => $leftIcon.width ?? 20}px;
        height: ${() => $leftIcon.height ?? 20}px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    `}

  ${({ $isLast }) =>
    $isLast &&
    css`
      margin-bottom: 15px;
    `}

  ${({ $isContactUsItem }) =>
    $isContactUsItem &&
    css`
      padding: 0 5px;
      border-top: 0;
      border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
      height: 50px;
    `}
`;

export const LabelNew = styled(Typography.Caption)`
  padding: 0 4px;
  border-radius: 20px;
  color: ${({ theme }) => theme.palette.system.white};
  background-color: ${({ theme }) => theme.palette.brand.green};
`;

export const LabeledNavItem = styled(BurgerNavItem)`
  display: flex;
  gap: 10px;

  a {
    width: max-content;
  }
`;
