import styled from 'styled-components';
import { Button } from '@/components/UI/Button/Button';
import { Typography } from '@/components/UI/Typography/Typography.styles';

export const Box = styled.div<{
  $isOpen?: boolean;
}>`
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateY(0)' : 'translateY(100%)'};
  transition: 0.3s ease-in-out;
  border-radius: 7px 7px 0 0;
  background-color: ${({ theme }) => theme.palette.primary.background};
  z-index: 10200;
  padding-bottom: ${({ theme }) =>
    theme.isWebView ? 'env(safe-area-inset-bottom)' : 0};
`;

export const Head = styled.div<{ $isTitleCentered: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${({ $isTitleCentered }) =>
    $isTitleCentered ? 'center' : 'flex-start'};
  align-items: center;
  width: 100%;
  height: 60px;
  padding-left: 15px;
  gap: 15px;
  color: ${({ theme }) => theme.palette.primary.labels};
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.border};
`;

export const Title = styled(Typography.Header)`
  max-width: 80%;
`;

export const Close = styled(Button)`
  position: absolute;
  right: 0;
`;

export const Body = styled.div`
  padding: 0 15px;
`;
