import React from 'react';
import { usePathname } from 'next/navigation';
import * as S from './LogoLink.styles';
import { ROUTES } from '@/constants/routes';
import {
  ASPECT_RATIO_COEFFICIENT,
  DATA_TEST_ID,
} from '@/components/LogoLink/LogoLink.constants';

interface LogoLinkProps {
  width?: number;
  onClick?: () => void;
}

export const LogoLink: React.FC<LogoLinkProps> = ({ width = 73, onClick }) => {
  const pathname = usePathname();

  return (
    <S.LogoLinkBox
      href={ROUTES.home}
      onClick={onClick}
      {...(pathname === ROUTES.home
        ? {
            as: 'a',
            'data-testid': DATA_TEST_ID,
          }
        : {
            dataTestId: DATA_TEST_ID,
          })}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src="/images/Tap.svg"
        alt="Tap.Az"
        width={width}
        height={Math.round(width / ASPECT_RATIO_COEFFICIENT)}
      />
    </S.LogoLinkBox>
  );
};
