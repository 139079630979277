import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './BurgerNav.styles';
import { Button } from '@/components/UI/Button/Button';
import { BottomSheet } from '@/components/UI/BottomSheet/BottomSheet';
import { useTheme } from '@/hooks/theme/useTheme';
import { ChangeLang } from '@/components/ChangeLang/ChangeLang';
import { Overlay } from '@/components/UI/Overlay/Overlay.styles';
import { makeHrefForPhone, sleep } from '@/utils/helpers';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';
import { CloseIcon, MenuIcon } from '@/components/UI/Icons/Icons';
import { ROUTES } from '@/constants/routes';
import { LogoLink } from '@/components/LogoLink/LogoLink';
import { Portal } from '@/components/UI/Portal/Portal';
import NoSsr from '@/components/NoSsr/NoSsr';
import { CONTACT_US } from '@/constants/contactUs';
import { CustomLink } from '@/components/CustomLink/CustomLink';
import { useBridgeState } from '@/bridge/hooks';
import { Languages } from '@/constants/common';

export const BurgerNav = () => {
  const { t, i18n } = useTranslation();
  const { isDarkTheme } = useTheme();
  const { isWebView } = useBridgeState();
  const [isOpen, setIsOpen] = useState(false);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isPhonesOpen, setIsPhonesOpen] = useState(false);

  const advertisingHref = ROUTES.advertising_landing(
    i18n.language === Languages.Az ? '' : `${Languages.Ru}.`
  );

  useBodyOverflow(isOpen);

  function toggleNav() {
    setIsOpen(prev => !prev);
  }

  async function toggleContactUs() {
    setIsContactUsOpen(prev => !prev);
    await sleep(500);
    setIsPhonesOpen(false);
  }

  function showPhones() {
    setIsPhonesOpen(true);
  }

  return (
    <>
      <Button
        data-testid="burger-nav-button"
        appearance="transparent"
        hasPadding={false}
        onClick={toggleNav}
      >
        <MenuIcon />
      </Button>
      <NoSsr>
        <Portal>
          <Overlay
            data-testid="burger-nav-overlay"
            $isOpen={isOpen}
            onClick={toggleNav}
          />

          <S.BurgerNavigation data-testid="burger-nav-drawer" $isOpen={isOpen}>
            <S.BurgerNavItem $isHead>
              <LogoLink onClick={toggleNav} />
              <S.BurgerNavClose
                data-testid="burger-nav-close"
                appearance="transparent"
                hasPadding={false}
                onClick={toggleNav}
              >
                <CloseIcon />
              </S.BurgerNavClose>
            </S.BurgerNavItem>

            {!isWebView && (
              <S.BurgerNavItem $leftIcon={{ name: 'language.svg' }}>
                <ChangeLang />
              </S.BurgerNavItem>
            )}

            <S.BurgerNavItem
              href={ROUTES.faq}
              as={CustomLink}
              $leftIcon={{ name: 'question.svg' }}
              target="_blank"
            >
              {t('common.help')}
            </S.BurgerNavItem>

            <S.BurgerNavItem
              $isLast
              onClick={toggleContactUs}
              $leftIcon={{ name: 'phone.svg' }}
            >
              {t('common.connect_with_us')}
            </S.BurgerNavItem>

            <S.BurgerNavItem
              href={ROUTES.terms}
              as={CustomLink}
              onClick={toggleNav}
              target="_blank"
            >
              {t('common.terms_of_use')}
            </S.BurgerNavItem>
            <S.BurgerNavItem
              href={ROUTES.rules}
              as={CustomLink}
              onClick={toggleNav}
              target="_blank"
            >
              {t('common.placement_rules')}
            </S.BurgerNavItem>
            <S.BurgerNavItem
              href={ROUTES.privacy}
              as={CustomLink}
              onClick={toggleNav}
              target="_blank"
            >
              {t('common.privacy_policy')}
            </S.BurgerNavItem>
            <S.LabeledNavItem>
              <S.BurgerNavItem
                href={advertisingHref}
                as={CustomLink}
                onClick={toggleNav}
                target="_blank"
                data-stat="advertising-landing-burger-btn"
              >
                {t('common.advertising_on_site')}
              </S.BurgerNavItem>
              <S.LabelNew $variant="3">{t('common.new_label')}</S.LabelNew>
            </S.LabeledNavItem>
          </S.BurgerNavigation>

          <BottomSheet
            dataTestId="burger-nav-contact-us"
            isOpen={isContactUsOpen}
            close={toggleContactUs}
            title={t('common.connect_with_us')}
          >
            <>
              {isPhonesOpen ? (
                <>
                  <S.BurgerNavItem
                    as="a"
                    $isLast
                    $isContactUsItem
                    href={makeHrefForPhone(CONTACT_US.PHONE)}
                    $leftIcon={{
                      name: `phone${isDarkTheme ? '-light' : ''}.svg`,
                      width: 17,
                      height: 17,
                    }}
                  >
                    {CONTACT_US.PHONE}
                  </S.BurgerNavItem>
                </>
              ) : (
                <>
                  <S.BurgerNavItem
                    $isContactUsItem
                    onClick={showPhones}
                    $leftIcon={{
                      name: `phone${isDarkTheme ? '-light' : ''}.svg`,
                      width: 17,
                      height: 17,
                    }}
                  >
                    {t('common.call')}
                  </S.BurgerNavItem>
                  <S.BurgerNavItem
                    $isContactUsItem
                    as="a"
                    href={CONTACT_US.FACEBOOK}
                    target="_blank"
                    $leftIcon={{
                      name: `facebook-alt${isDarkTheme ? '-light' : ''}.svg`,
                      width: 17,
                      height: 17,
                    }}
                  >
                    Facebook
                  </S.BurgerNavItem>
                  <S.BurgerNavItem
                    $isContactUsItem
                    as="a"
                    href={CONTACT_US.INSTAGRAM}
                    target="_blank"
                    $leftIcon={{
                      name: `instagram-alt${isDarkTheme ? '-light' : ''}.svg`,
                      width: 17,
                      height: 17,
                    }}
                  >
                    Instagram
                  </S.BurgerNavItem>
                  <S.BurgerNavItem
                    $isLast
                    $isContactUsItem
                    as="a"
                    href={`mailto:${CONTACT_US.EMAIL}`}
                    target="_blank"
                    $leftIcon={{
                      name: `envelope${isDarkTheme ? '-light' : ''}.svg`,
                      width: 17,
                      height: 17,
                    }}
                  >
                    {t('common.write')}
                  </S.BurgerNavItem>
                </>
              )}
            </>
          </BottomSheet>
        </Portal>
      </NoSsr>
    </>
  );
};
