import styled from 'styled-components';
import { InputBox } from '@/components/UI/Input/Input.styles';
import { Button } from '@/components/UI/Button/Button';

export const Box = styled.div<{
  $hasPaddingTop?: boolean;
}>`
  display: flex;
  gap: 5px;
  background-color: ${({ theme }) => theme.palette.primary.background};
  padding: ${({ $hasPaddingTop }) => ($hasPaddingTop ? '15px' : '0 15px 12px')};
  position: relative;
  z-index: 10;
`;

export const Form = styled.form<{ $isFocused: boolean }>`
  display: flex;
  width: 100%;
  border-radius: 14px;
  border: 1px solid
    ${({ theme, $isFocused }) =>
      $isFocused ? theme.palette.brand.main : theme.palette.secondary.border};
`;

export const Input = styled(InputBox)`
  padding: 0;
`;

export const CloseButton = styled(Button)`
  margin-right: 15px;
`;
