import styled, { css } from 'styled-components';
import { Overlay } from '@/components/UI/Overlay/Overlay.styles';
import { DrawerItem } from '@/components/Drawer/Drawer.styles';
import { ellipsisMultiline } from '@/styles/helpers';

export const Box = styled.div<{
  $isOpen?: boolean;
  $isHome?: boolean;
  $isTopControlsFixed?: boolean;
  $offsetTop: number;
}>`
  position: ${({ $isTopControlsFixed }) =>
    $isTopControlsFixed ? 'fixed' : 'absolute'};
  top: ${({ $offsetTop }) => `${$offsetTop}px`};
  left: 0;
  width: 100%;
  z-index: 10021;
  );

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  > h6 {
    padding: 15px;
  }
`;

export const Item = styled(DrawerItem)`
  justify-content: space-between;
  gap: 15px;
`;

export const Text = styled.p`
  ${ellipsisMultiline(1)};
`;

export const OverlayCustom = styled(Overlay)<{ $isHome?: boolean }>`
  transition: none;
  z-index: 9;
`;

export const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategoryText = styled.p`
  font-size: 14px;
  margin-top: 2px;
  color: ${({ theme }) => theme.palette.secondary.labels};
`;
