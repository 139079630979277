import React, { PropsWithChildren } from 'react';
import { FieldValues, Path } from 'react-hook-form';
import * as S from './Select.styles';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { RequiredStar } from '@/components/UI/RequiredStar/RequiredStar';
import { DrawerItems } from '@/components/Drawer/Drawer.styles';
import { Checkbox } from '@/components/UI/Checkbox/Checkbox';
import { Drawer } from '@/components/Drawer/Drawer';
import { HelperText } from '@/components/UI/HelperText/HelperText';
import { ConditionalSelectBaseProps } from '@/components/UI/Select/Select.types';
import { InputClear } from '@/components/UI/Input/Input.styles';

export function Select<TFiledValues extends FieldValues>({
  children: drawer,
  label,
  iconSrc,
  hasIcon,
  toggle,
  value,
  hasBg,
  hasBorderBottom,
  isRequired,
  options,
  isOpen,
  onSelect,
  trigger,
  helperText,
  hasError,
  visibleValue,
  name,
  onClear,
}: PropsWithChildren<ConditionalSelectBaseProps<TFiledValues>>) {
  async function onOptionClick(option: string) {
    toggle();
    onSelect?.(option);
    await trigger?.(
      name as unknown as
        | Path<TFiledValues>
        | Path<TFiledValues>[]
        | readonly Path<TFiledValues>[]
        | undefined
    );
  }

  const selectValue = visibleValue ?? value;

  function clear(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    onClear?.();
  }

  return (
    <>
      <S.SelectBox
        onClick={toggle}
        $hasBg={hasBg}
        $hasBorderBottom={hasBorderBottom}
        data-testid="select"
      >
        <S.SelectValue data-testid="select-value">
          {hasIcon && (
            <S.SelectImage $isDefaultIcon={!iconSrc}>
              {iconSrc && (
                // eslint-disable-next-line @next/next/no-img-element
                <img src={iconSrc} alt={label} width={35} height={35} />
              )}
            </S.SelectImage>
          )}
          <S.SelectTexts>
            {value ? (
              <>
                <S.SelectLabelSmall data-testid="select-label-small">
                  {label} {isRequired && <RequiredStar />}
                </S.SelectLabelSmall>
                <S.SelectText data-testid="select-text">
                  {selectValue}
                </S.SelectText>{' '}
              </>
            ) : (
              <S.SelectLabel data-testid="select-label">
                {label} {isRequired && <RequiredStar />}
              </S.SelectLabel>
            )}
          </S.SelectTexts>
        </S.SelectValue>

        {selectValue && onClear ? (
          <InputClear $isYCentered onClick={clear} data-testid="select-clear" />
        ) : (
          <S.SelectAngle data-testid="select-angle">
            <AngleBackIcon />
          </S.SelectAngle>
        )}
      </S.SelectBox>

      {helperText && <HelperText helperText={helperText} hasError={hasError} />}

      {options ? (
        <Drawer
          isOpen={isOpen}
          title={label}
          left={{ icon: <AngleBackIcon />, onClick: toggle }}
        >
          <DrawerItems>
            {options.map(option => (
              <Checkbox
                onChangeHook={() => onOptionClick(option.value)}
                key={option.value}
                value={option.value === value}
                label={option.label}
                appearance="radio"
                size="sm"
              />
            ))}
          </DrawerItems>
        </Drawer>
      ) : (
        drawer
      )}
    </>
  );
}
