import React from 'react';
import * as S from '@/components/TopActions/TopActions.styles';
import { BurgerNav } from '@/components/BurgerNav/BurgerNav';
import { PlusIcon, CloseWebviewIcon } from '@/components/UI/Icons/Icons';
import { ROUTES } from '@/constants/routes';
import { LogoLink } from '@/components/LogoLink/LogoLink';
import { useSaveScrollPositionActions } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { useBridgeAPI, useBridgeState } from '@/bridge/hooks';

export const TopActions = () => {
  const saveScrollPositionActions = useSaveScrollPositionActions();
  const { closeWebview } = useBridgeAPI();
  const { isWebView } = useBridgeState();

  const CloseWebViewButton = (
    <S.CloseWebviewButton appearance="transparent" onClick={closeWebview}>
      <CloseWebviewIcon />
    </S.CloseWebviewButton>
  );

  const NewAdButton = (
    <S.NewAdButton
      dataTestId="header-add-new-ad-button"
      onClick={() => saveScrollPositionActions()}
      href={ROUTES.newAd}
    >
      <PlusIcon />
    </S.NewAdButton>
  );

  return (
    <S.Box data-testid="top-actions">
      <BurgerNav />

      <LogoLink />

      {isWebView ? CloseWebViewButton : NewAdButton}
    </S.Box>
  );
};
