import { gql } from '@apollo/client';

export const GET_SEARCH_SUGGESTIONS = gql`
  query SearchSuggestions($keywords: String!) {
    searchSuggestions(keywords: $keywords) {
      value
      subvalue
      properties
      categoryPath
    }
  }
`;
